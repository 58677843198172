export enum UserRole {
  SUPER_ADMIN = 'Super admin',
  ADMIN = 'Admin',
  HOST = 'Host',
  USER = 'User',
}

export interface IUser {
  id: number;
  name: string;
  lastName: string;
  email: string;
  role: UserRole;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
