import Joi from 'joi';

import {
  ExperienceCategories,
  SubscriptionPlan,
  ExperienceLanguages
} from '../../interfaces/IExperience';

export const experienceSchema = Joi.object({
  name: Joi.string().min(2).max(50).required().messages({
    'string.empty': 'Name is required',
    'string.min': 'Name should have more than 2 characters',
    'string.max': 'Name should have less than 50 characters',
    'any.required': 'Name is required',
  }),

  description: Joi.string().min(2).max(500).required().messages({
    'string.empty': 'Description is required',
    'string.min': 'Description should have more than 2 characters',
    'string.max': 'Description should have less than 500 characters',
    'any.required': 'Description is required',
  }),

  instructions: Joi.string().min(2).max(500).required().messages({
    'string.empty': 'Instructions field is required',
    'string.min': 'Instructions should have more than 2 characters',
    'string.max': 'Instructions should have less than 500 characters',
    'any.required': 'Instructions is required',
  }),

  durationInMinutes: Joi.number().required().min(1).integer().messages({
    'any.required': 'Duration in minutes is required',
    'number.base': 'Duration in minutes must be a number',
    'number.min': 'Duration in minutes must be greater than 0',
  }),

  category: Joi.string()
    .valid(...Object.values(ExperienceCategories))
    .required()
    .messages({
      'string.empty': 'Category is required',
      'any.only': 'Invalid type selected',
      'any.required': 'Category is required',
    }),

  allowInPlan: Joi.number()
    .valid(SubscriptionPlan.FREE, SubscriptionPlan.PRO)
    .required()
    .messages({
      'any.required': 'Allow in plan is required',
    }),

  templateUrl: Joi.string().required().messages({
    'string.empty': 'Template URL field is required',
    'any.required': 'Template URL field is required',
  }),
  imageUrl: Joi.string().required().messages({
    'string.empty': 'Image is required',
  }),
  documentUrl: Joi.string().allow(''),
  customMessage: Joi.string().allow(''),
  language: Joi.string()
    .allow()
    .valid(...Object.values(ExperienceLanguages))
    .required()
    .messages({
      'string.empty': 'Language is required',
      'any.only': 'Invalid type selected',
      'any.required': 'Language is required',
    }),
});
