import type { IExperience } from 'src/interfaces/IExperience';
import type { ISession } from 'src/interfaces/ISession';
import type { IUser } from 'src/interfaces/IUser';

import { get, post, put } from '../api-axios';

export interface IPaginatedQuery {
  offset: number;
  limit: number;
}
export const getUsers = async (query?: IPaginatedQuery) => {
  return await get<{ users: IUser[]; count: number }>('/user', {
    params: query,
  });
};

export const updateUser = async (
  userId: number,
  updatedUser: Partial<IUser>,
) => {
  return await put(`/user/${userId}`, updatedUser);
};

export const getExperiences = async (query?: IPaginatedQuery) => {
  return await get<{ experiences: IExperience[]; count: number }>(
    '/experiences',
    {
      params: query,
    },
  );
};

export const createExperience = async (
  newExperience: Omit<
    IExperience,
    | 'id'
    | 'totalRatings'
    | 'totalRaters'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
  >,
) => {
  return await post<IExperience>('/experiences', newExperience);
};

export const updateExperience = async (
  updatedExperience: Omit<
    IExperience,
    'totalRatings' | 'totalRaters' | 'createdAt' | 'updatedAt' | 'deletedAt'
  >,
) => {
  return await put(`/experiences/${updatedExperience.id}`, updatedExperience);
};

export const createSession = async (
  experienceId: number,
  hostId: number,
  userIds: number[],
) => {
  return await post<ISession>(`/sessions`, {
    experienceId,
    hostId,
    userIds,
  });
};

export const getSessions = async (query?: IPaginatedQuery) => {
  return await get<{ sessions: ISession[]; count: number }>('/sessions', {
    params: query,
  });
};

export const updateSession = async (
  sessionId: number,
  updatedSession: Partial<ISession>,
) => {
  return await put(`/sessions/${sessionId}`, updatedSession);
};

export const submitFeedback = async (
  sessionId: number,
  feedback: { rating: number; comment: string },
) => {
  return await put(`/sessions/${sessionId}/feedback`, feedback);
};

export const uploadImage = async (image: File) => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('folder', 'experience-image');
  return await post<string>('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder', 'documents');
  return await post<string>('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
