export enum ExperienceCategories {
  category1 = 'Category1',
  category2 = 'Category2',
  category3 = 'Category3',
}

export enum SubscriptionPlan {
  FREE = 0,
  PRO = 1,
}
export const planNames = ['Free', 'Pro'] as const;

export enum ExperienceLanguages {
  english = 'English',
  spanish = 'Spanish',
}

export interface IExperience {
  id: number;
  name: string;
  description: string;
  instructions: string;
  templateUrl: string;
  imageUrl: string;
  documentUrl: string;
  allowInPlan: SubscriptionPlan;
  category: ExperienceCategories;
  language: ExperienceLanguages;
  totalRatings: number;
  totalRaters: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
