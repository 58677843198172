<script lang="ts">
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import { onMount } from 'svelte';
  import { Route, Router } from 'svelte-navigator';

  import '../src/app.css';
  import Loading from './lib/components/loading/Loading.svelte';
  import Sidebar from './lib/components/sidebar/Sidebar.svelte';
  import { refreshSession } from './lib/services/together/user.api';
  import BoxForm from './routes/box-form/BoxForm.svelte';
  import Boxes from './routes/boxes/Boxes.svelte';
  import ExperienceForm from './routes/experience-form/ExperienceForm.svelte';
  import Experiences from './routes/experiences/Experiences.svelte';
  import GameBoxRelationForm from './routes/game-box-relation-form/GameBoxRelationForm.svelte';
  import GameForm from './routes/games-form/GameForm.svelte';
  import Games from './routes/games/Games.svelte';
  import Login from './routes/login/Login.svelte';
  import MiniGameForm from './routes/mini-games-form/MiniGameForm.svelte';
  import MiniGames from './routes/mini-games/MiniGames.svelte';
  import Submissions from './routes/submissions/Submissions.svelte';
  import User from './routes/user/User.svelte';
  import ViewBox from './routes/view-box/ViewBox.svelte';
  import ViewExperience from './routes/view-experience/ViewExperience.svelte';
  import ViewGame from './routes/view-game/ViewGame.svelte';
  import ViewMiniGame from './routes/view-mini-game/ViewMiniGame.svelte';
  import { userStore } from './store/userStore';

  let checkAuth = false;
  onMount(() => {
    refreshSession().finally(() => {
      checkAuth = true;
    });
  });
</script>

<Router primary={false}>
  <div class="wrap">
    <SvelteToast />
  </div>

  <div class="new-container">
    <SvelteToast
      target="new-container"
      options={{ initial: 0, intro: { y: -64 } }}
    />
  </div>
  {#if checkAuth}
    <main class="min-h-screen max-h-fit">
      {#if !$userStore}
        <Route path="/" component={Login} />
      {/if}
      {#if $userStore}
        <div class="relative md:ml-64 bg-gray-100">
          <Sidebar />
          <Route path="/admin/" component={User} />
          <Route path="/admin/boxes" component={Boxes} />
          <Route path="/admin/games" component={Games} />
          <Route path="/admin/submissions" component={Submissions} />
          <Route path="/admin/experiences" component={Experiences} />
          <Route path="/admin/add/experience" component={ExperienceForm} />
          <Route path="/admin/edit/experience/:id" component={ExperienceForm} />
          <Route path="/admin/mini-games" component={MiniGames} />
          <Route path="/admin/add/box" component={BoxForm} />
          <Route path="/admin/edit/box/:id" component={BoxForm} />
          <Route path="/admin/add/game" component={GameForm} />
          <Route path="/admin/add/mini-game" component={MiniGameForm} />
          <Route path="/admin/edit/game/:id" component={GameForm} />
          <Route path="/admin/edit/mini-game/:id" component={MiniGameForm} />
          <Route path="/admin/box/:id" component={ViewBox} />
          <Route path="/admin/game/:id" component={ViewGame} />
          <Route path="/admin/experience/:id" component={ViewExperience} />
          <Route path="/admin/mini-game/:id" component={ViewMiniGame} />
          <Route
            path="/admin/add-or-delete-game-to-box/"
            component={GameBoxRelationForm}
          />
        </div>
      {/if}
    </main>
  {:else}
    <main
      class="min-h-screen min-w-screen flex flex-col items-center justify-center"
    >
      <Loading loadingSize="h-32 w-32" />
    </main>
  {/if}
</Router>

<style>
  .wrap {
    display: contents;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    --toastBackground: white;
    --toastColor: #030303;
  }

  .new-container {
    --toastContainerTop: 0.5rem;
    --toastContainerRight: 0.5rem;
    --toastContainerBottom: auto;
    --toastContainerLeft: 0.5rem;
    --toastWidth: 100%;
    --toastMinHeight: 2rem;
    --toastPadding: 0 0.5rem;
  }

  @media (min-width: 40rem) {
    .new-container {
      --toastContainerRight: auto;
      --toastContainerLeft: calc(50vw - 20rem);
      --toastWidth: 40rem;
    }
  }

  .wrap :global(strong) {
    font-weight: 600;
  }
</style>
