<script lang="ts">
  import moment from 'moment';
  import { onMount } from 'svelte';
  import { link } from 'svelte-navigator';

  import { planNames } from '../../interfaces/IExperience';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import { get } from '../../lib/services/api-axios';
  import type { IExperienceByIdResponse } from './IExperienceByIdResponse';

  export let id: string;

  interface IViewExperience {
    Id: string;
    Description: string;
    Name: string;
    Instructions: string;
    'Template url': string;
    'Duration in minutes': number;
    'Custom message': string;
    Category: string;
    Plan: string;
    Language: string;
    'Created at': string;
    'Updated at': string;
  }

  let experienceFields: IViewExperience | null = null;
  let loading = true;
  let error: string | null = null;
  let image = '';
  let documentUrl: string | null = null;

  const getExperienceById = async () => {
    try {
      const experience = await get<IExperienceByIdResponse>(
        `/experiences/${id}`,
      );
      image = experience.imageUrl;
      documentUrl = experience.documentUrl ?? null;

      experienceFields = {
        Id: id,
        Name: experience.name,
        Description: experience.description,
        Instructions: experience.instructions,
        'Template url': experience.templateUrl,
        'Duration in minutes': experience.durationInMinutes,
        'Custom message': experience.customMessage,
        Category: experience.category,
        Plan: planNames[experience.allowInPlan],
        Language: experience.language,
        'Created at': experience.createdAt,
        'Updated at': experience.updatedAt,
      };
    } catch (err) {
      error = 'Failed to fetch experience';
    } finally {
      loading = false;
    }
  };

  onMount(() => {
    getExperienceById();
  });

  const formatValue = (key: string, value: any) => {
    if (key === 'Created at' || key === 'Updated at') {
      return moment(value).format('MMMM Do YYYY');
    }
    return value || '-';
  };
</script>

{#if loading}
  <div class="flex flex-col items-center justify-center min-h-screen p-4">
    <Loading loadingSize="h-20 w-20" />
  </div>
{:else if error}
  <p>{error}</p>
{:else if experienceFields}
  <div class="flex flex-col items-center min-h-screen p-4">
    <div
      class="mb-4 md:w-1/2 w-full flex md:flex-row flex-col justify-between items-center"
    >
      <h1 class="text-2xl font-bold my-4">{experienceFields.Name}</h1>

      <a
        class="text-white text-center rounded-md w-full lg:w-auto px-4 py-2 text-sm font-bold bg-primary hover:bg-accent hover:text-black transition ease-in duration-200"
        href="/admin/edit/experience/{experienceFields.Id}"
        use:link
      >
        Edit
      </a>
    </div>
    {#each Object.entries(experienceFields) as [key, value]}
      {#if key !== 'Name'}
        <div class="mb-4 md:w-1/2 w-full">
          <h2 class="text-lg font-medium text-gray-900">{key}</h2>
          <p class="py-1.5 px-4 text-gray-900 bg-white rounded-md">
            {formatValue(key, value)}
          </p>
          {#if key === 'Duration in minutes'}
            <div class="my-4 md:w-1/2 w-full">
              <h2 class="text-lg font-medium text-gray-900">Image</h2>
              <img src={image} alt="experience thumbnail" />
            </div>
            <div class="my-4 md:w-1/2 w-full">
              <h2 class="text-lg font-medium text-gray-900">Document</h2>
              <p class="py-1.5 px-4 bg-white rounded-md">
                {#if documentUrl}
                  <a class="text-primary" href={documentUrl}
                    >{documentUrl.split('/').pop()}</a
                  >
                {:else}
                  No document
                {/if}
              </p>
            </div>
          {/if}
        </div>
      {/if}
    {/each}
  </div>
{/if}
